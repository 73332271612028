<template
  ><div style="height:45px;"><component :is="toolbarType"></component></div>
</template>

<script>
import ConversationActionToolbar from "./conversation-actions-toolbar";
import ConversationListActionToolbar from "./conversation-list-actions-toolbar";

export default {
  components: {
    "conversation-action-toolbar": ConversationActionToolbar,
    "conversation-list-action-toolbar": ConversationListActionToolbar
  },
  computed: {
    toolbarType: function() {
      if (this.action === "conversation" || this.action === "singleDraftConversation")
        return "conversation-action-toolbar";
      if (this.action === "conversation-list")
        return "conversation-list-action-toolbar";
      if (this.action === "compose") return "";
    },

    action: function() {
      return this.$store.state.messagingModule.action;
    }
  }
};
</script>
