<template>
  <div>
    <form-field :field="content"></form-field>
    <div v-if="hasAttachments">
      <div class="divider mt-4 mb-4"></div>
      <attachment-list class="ml-2" :messageIndex="index"></attachment-list>
    </div>
    <respond-actions class="ml-5 mt-8" :index="index"></respond-actions>
  </div>
</template>
<script>
import AttachmentList from "../Attachments/ViewAttachment/attachment-list";
import RespondActions from "./RespondActions/respond-actions";
import FormField from "../../../objs/Shared/FormField";
import { FieldTypes } from "../../../objs/Shared/FieldTypes";
import { mapState } from "vuex";
export default {
  props: ["index"],
  components: {
    "respond-actions": RespondActions,
    "attachment-list": AttachmentList
  },
  computed: {
    ...mapState("messagingModule", ["conversation"]),

    content: function() {
      return new FormField({
        tier: 4,
        moduleName: "messagingModule",
        objName: "conversation",
        propName: "messages",
        index: this.index,
        subPropName: "content",
        componentName: FieldTypes.TEXT_EDITOR_READONLY
      });
    },
    hasAttachments: function() {
      return this.conversation.messages[this.index].attachments.length > 0;
    }
  }
};
</script>
<style>
.divider {
  border-top: 1px dotted #d8d8d8;
  width: 90%;
}
</style>
