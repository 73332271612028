import User from "./User";
import Attachment from "./Attachment";

export default class Message {
  messageId?: number | null;
  senderId?: number | null;
  recipientIds?: number[];
  content?: string | null;
  conversationId?: number | null;
  subject?: string | null;
  public constructor(params: Message = {} as Message) {
    let {
      messageId = 0,
      senderId = 0,
      recipientIds = new Array<number>(),
      content = "",
      conversationId = 0,
      subject = ""
    } = params;
    this.messageId = messageId;
    this.senderId = senderId;
    this.recipientIds = recipientIds;
    this.content = content;
    this.conversationId = conversationId;
    this.subject = subject;
  }
}
