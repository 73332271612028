<template>
  <div v-show="showRespondButtons">
    <reply-button
      v-on:respondActionClicked="hideButtonBar"
      :index="index"
    ></reply-button>
    <reply-all-button
      v-on:respondActionClicked="hideButtonBar"
      :index="index"
      v-show="hasMultipleRecipients"
    ></reply-all-button>
    <forward-button
      v-on:respondActionClicked="hideButtonBar"
      :index="index"
    ></forward-button>
  </div>
</template>
<script>
import ReplyButton from "./reply-btn";
import ReplyAllButton from "./reply-all-btn";
import ForwardButton from "./forward-btn";
import { mapState } from "vuex";
export default {
  props: ["index"],
  components: {
    "reply-button": ReplyButton,
    "reply-all-button": ReplyAllButton,
    "forward-button": ForwardButton
  },
  data() {
    return {
      showRespondButtons: true
    };
  },
  computed: {
    ...mapState("messagingModule", ["conversation"]),
    hasMultipleRecipients: function() {
      return this.conversation.messages[this.index].recipients.length > 1;
    }
  },
  methods: {
    hideButtonBar: function() {
      this.showRespondButtons = false;
    }
  }
};
</script>
