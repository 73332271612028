<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        large
        color="#1D3557"
        dark
        v-on:click="backClicked"
        v-bind="attrs"
        v-on="on"
      >
        <icon icon="arrow-left"></icon>
      </v-btn>
    </template>
    <span>Back to {{ conversationType }}</span>
  </v-tooltip>
</template>

<script>
export default {
  computed: {
    conversationType: function() {
      return this.$store.state.messagingModule.selectedConversationType;
    }
  },
  methods: {
    backClicked: function() {
      this.$router.push({ name: this.conversationType });
    }
  }
};
</script>
