export default class User {
  messagingUserId?: number | null;
  messagingAddress?: string | null;
  displayName?: string | null;
  public constructor(params: User = {} as User) {
    let {
      messagingUserId = 0,
      messagingAddress = "",
      displayName = ""
    } = params;
    this.messagingUserId = messagingUserId;
    this.messagingAddress = messagingAddress;
    this.displayName = displayName;
  }
}
