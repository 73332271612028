<template>
  <v-btn outlined color="#1D3557" dark v-on:click="forward">
    <icon icon="forward"></icon><span class="lowercase ml-1">Forward</span>
  </v-btn>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["index"],
  methods: {
    ...mapActions("messagingModule", ["forwardMessage"]),
    forward: function() {
      this.forwardMessage({ messageIndex: this.index });
      this.$emit("respondActionClicked");
    }
  }
};
</script>
