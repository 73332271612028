import axios from "axios";
var urlPrefix = "https://api.omniuserportal.com";

export async function getMessagingUser(payload) {
  return axios
    .get(urlPrefix + "/api/messaging/getMessagingUserByOmniUserId", {
      params: { userId: payload }
    })
    .then(response => {
      console.log("response " + response);
      return response.data;
    })
    .catch(error => {
      console.log("Error " + error);
      return error;
    });
}
export async function getMessagingUsers() {
  console.log("in messaging api get messaging users");
  return axios
    .get(urlPrefix + "/api/messaging/getMessagingUsers")
    .then(response => {
      console.log("response " + response);
      return response.data;
    })
    .catch(error => {
      console.log("Error " + error);
      return error;
    });
}

export async function getConversationList(payload) {
  console.log("in messaging api get inbox");
  return axios
    .get(urlPrefix + "/api/messaging/getAllConversations", {
      params: { messagingUserId: payload }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
// export async function getInbox(payload) {
//   console.log("in messaging api get inbox");
//   return axios
//     .get(urlPrefix + "/api/messaging/getInbox", {
//       params: { messagingUserId: payload }
//     })
//     .then(response => {
//       return response.data;
//     })
//     .catch(error => {
//       return error;
//     });
// }
// export async function getSent(payload) {
//   return axios
//     .get(urlPrefix + "/api/messaging/getSent", {
//       params: { messagingUserId: payload }
//     })
//     .then(response => {
//       return response.data;
//     })
//     .catch(error => {
//       return error;
//     });
// }
// export async function getDrafts(payload) {
//   return axios
//     .get(urlPrefix + "/api/messaging/getDrafts", {
//       params: { messagingUserId: payload }
//     })
//     .then(response => {
//       return response.data;
//     })
//     .catch(error => {
//       return error;
//     });
// }
export async function search(id, searchText) {
  return axios
    .get(urlPrefix + "/api/messaging/search", {
      params: { messagingUserId: id, searchText: searchText }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function getConversation(userId, conversationId) {
  return axios
    .get(urlPrefix + "/api/messaging/getFullConversation", {
      params: { messagingUserId: userId, conversationId: conversationId }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function createNewMessageInNewConversation(data) {
  console.log("in create new message new convo");
  return axios
    .post(urlPrefix + "/api/messaging/createNewMessageInNewConversation", data)
    .then(response => {
      console.log("Response" + response);
      return response.data;
    })
    .catch(error => {
      console.log("error" + error);
      return error;
    });
}
export async function createNewMessageInExistingConversation(data) {
  console.log("create new message existing convo");
  return axios
    .post(
      urlPrefix + "/api/messaging/createNewMessageInExistingConversation",
      data
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function sendMessage(data) {
  return axios
    .post(urlPrefix + "/api/messaging/sendMessage", data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function updateDraftMessage(data) {
  return axios
    .post(urlPrefix + "/api/messaging/updateDraftMessage", data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function deleteDraft(payload) {
  return axios
    .post(urlPrefix + "/api/messaging/deleteDraft", null, {
      params: { messageId: payload }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function markAsRead(userId, conversationId) {
  return axios
    .post(urlPrefix + "/api/messaging/markConversationAsRead", null, {
      params: { messagingUserId: userId, conversationId: conversationId }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function deleteConversation(userId, conversationId) {
  return axios
    .post(urlPrefix + "/api/messaging/deleteConversation", null, {
      params: { messagingUserId: userId, conversationId: conversationId }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function addAttachmentToMessage(formData) {
  return axios
    .put(urlPrefix + "/api/messaging/addAttachmentToMessage", formData)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function getAttachment(payload) {
  return axios
    .get(urlPrefix + "/api/messaging/getAttachment", {
      params: { fileId: payload }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function removeAttachmentFromMessage(data) {
  return axios
    .post(urlPrefix + "/api/messaging/removeAttachmentFromMessage", data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function forwardAttachment(fileId, messageId) {
  return axios
    .post(urlPrefix + "/api/messaging/forwardAttachment", null, {
      params: { fileId: fileId, messageId: messageId }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function createGroup(data) {
  return axios
    .post(urlPrefix + "/api/messaging/createGroup", data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function updateGroup(data) {
  return axios
    .post(urlPrefix + "/api/messaging/updateGroup", data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function deleteGroup(payload) {
  return axios
    .post(urlPrefix + "/api/messaging/deleteGroup", null, {
      params: { groupId: payload }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function getMessagingGroups(payload) {
  console.log("calling getGroupsForuser , messaging id is " + payload);
  return axios
    .get(urlPrefix + "/api/messaging/getGroupsForUser", {
      params: { messagingUserId: payload }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
