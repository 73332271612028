<template>
  <div style="height: 515px;" v-if="loaded">
    <v-row>
      <v-col cols="12" sm="6">
        <form-field
          class="compose-recipients"
          :field="recipients"
        ></form-field> </v-col
    ></v-row>
    <div
      id="style-11"
      class="w-9"
      style="overflow-x: hidden; overflow-y: auto; height:55%;"
    >
      <form-field :field="content"></form-field>
    </div>
    <div
      id="style-11"
      class="mt-4"
      style="overflow-x: hidden; overflow-y: auto; height:10%;"
    >
      <attachment-file-list :index="index"></attachment-file-list>
    </div>
    <message-actions :index="index"></message-actions>
  </div>
</template>
<script>
import MessageActions from "../MessageActions/message-actions";
import AttachmentFileList from "../Attachments/AddAttachment/attachment-file-list";
import FormField from "../../../objs/Shared/FormField";
import { FieldTypes } from "../../../objs/Shared/FieldTypes";
export default {
  props: ["index"],
  components: {
    "message-actions": MessageActions,
    "attachment-file-list": AttachmentFileList
  },
  data() {
    return {
      messageWasUpdated: false
    };
  },
  computed: {
    loaded: function() {
      return this.message != null;
    },
    userList: function() {
      return this.$store.getters["messagingModule/getUserList"];
    },
    recipients: function() {
      return new FormField({
        tier: 4,
        moduleName: "messagingModule",
        objName: "conversation",
        propName: "messages",
        index: this.index,
        subPropName: "recipients",
        componentName: "recipients",
        label: "Recipients"
      });
    },

    content: function() {
      return new FormField({
        tier: 4,
        moduleName: "messagingModule",
        objName: "conversation",
        propName: "messages",
        index: this.index,
        subPropName: "content",
        componentName: FieldTypes.TEXT_EDITOR_FULL_EXTENSION_LIST
      });
    },
    message() {
      return this.$store.state.messagingModule.conversation.messages[
        this.index
      ];
    },
    draftIsDeleted: function() {
      return this.$store.state.messagingModule.deleteDraftClicked;
    },
    draftWasUpdated: function() {
      return this.messageWasUpdated && this.message.isDraft;
    },
    newMessage: function() {
      return this.message.messageId == 0;
    },
    messageContent: function() {
      return this.message.content;
    },
    messageContentNotEmpty: function() {
      return this.messageContent != "";
    }
  },
  watch: {
    message: {
      handler() {
        this.messageWasUpdated = true;
      },
      deep: true
    }
  },
  methods: {
    saveMessage: function() {
      if (this.newMessage) {
        console.log("this is a new message");
        this.saveNewMessage();
      } else {
        console.log("this is an old message");
        this.updateExistingMessage();
      }
    },
    saveNewMessage: function() {
      if (this.messageContentNotEmpty) {
        this.$store
          .dispatch("messagingModule/createNewMessage", {
            index: this.index
          })
          .then(response => {
            this.$store.dispatch(
              "messagingModule/uploadAttachmentsForMessage",
              {
                index: this.index
              }
            );
          })
          .then(response => {
            this.$store.commit("uxModule/setShowSnackbar", false);
            this.$store.commit("uxModule/setSnackbarMsg", "Draft Saved");
            this.$store.commit("uxModule/setShowSnackbar", true);
          });
      }
    },
    async updateExistingMessage() {
      await this.uploadAttachments();
      this.$store.dispatch("messagingModule/updateDraftMessage", {
        index: this.index
      });
    },
    async uploadAttachments() {
      return this.$store.dispatch(
        "messagingModule/uploadAttachmentsForMessage",
        {
          index: this.index
        }
      );
    }
  },
  beforeDestroy() {
    if (this.loaded) {
      if (this.draftWasUpdated) {
        console.log("saving message");
        this.saveMessage();
      }
    }
    this.$store.commit("messagingModule/setDeleteDraftClicked", false);
  }
};
</script>
