<template>
  <v-row dense>
    <v-col cols="12" sm="6" class="d-flex">
      <v-text-field
        dense
        width=""
        clearable
        v-model="searchPhrase"
        single-line
        filled
        label="Search mail"
      ></v-text-field> 
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            v-bind="attrs"
            v-on="on"
            color="#88acd4"
            @click="search"
            class="mt-1 ml-1"
            ><icon class="fa-lg" icon="search"></icon
          ></v-btn>
        </template>
        <span>Search</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>
<script>
export default {
  computed: {
    searchPhrase: {
      get() {
        return this.$store.state.messagingModule.searchPhrase;
      },
      set(event) {
        this.$store.commit("messagingModule/setSearchPhrase", { value: event });
      }
    }
  },
  methods: {
    search: function() {
      this.$store.dispatch("messagingModule/search").then(response => {
        this.$router.push({ name: "Search" });
      });
    }
  }
};
</script>
