<template>
  <div v-if="numberOfMessages == 1">
    <single-message-display></single-message-display>
  </div>

  <div v-else>
    <message-list></message-list>
  </div>
</template>
<script>
import SingleMessageDisplay from "./single-message-display";
import MessageList from "./message-list-expandable";
export default {
  components: {
    "message-list": MessageList,
    "single-message-display": SingleMessageDisplay
  },
  computed: {
    conversation: function() {
      return this.$store.state.messagingModule.conversation;
    },
    numberOfMessages: function() {
      return this.conversation.messages.length;
    }
  }
};
</script>
