<template
  ><v-col cols="12" md="2">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card style="border:1px grey;" class="cardWidth">
          <!-- <div style="height:100px;overflow:hidden; background-color:grey;"> -->
          <!--TODO finish this when controller is finished and getAttachment
            returns a File/blob -->
          <!-- <img
              style="border: 0px none;  height: 200px;  width: 200px; "
              :src="url"
              v-if="attachment.fileType.startsWith('image/')"
            />
            <iframe
              v-else
              scrolling="no"
              style="border: 0px none; margin-left: -80px; margin-top:-70px;height: 200px;  width: 400px;"
              :type="attachment.fileType"
              :src="url"
            /> -->
          <!-- </div> -->

          <v-card-text>
            <!-- <v-row dense class="text-center" justify="center" align="center"> -->
            <!-- <v-col cols="10"> -->
            <span :class="fileNameClass">
              {{ fileName }}<icon class="fa-lg ml-2" :icon="attachmentTypeIcon"></icon
            ></span>
            <!-- </v-col> -->
            <!-- <v-col cols="auto"> -->

            <!-- </v-col> -->
            <!-- </v-row> -->
          </v-card-text>
          <v-fade-transition>
            <v-overlay v-if="hover" absolute color="#036358">
              <v-row class="text-center">
                <span class="ml-2 text-caption">
                  {{ attachmentReadableSize }}
                </span> </v-row
              ><v-row>
                <download-attachment-btn
                  :messageIndex="messageIndex"
                  :attachmentIndex="attachmentIndex"
                ></download-attachment-btn
              ></v-row>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template> </v-hover
  ></v-col>
</template>

<script>
import DownloadAttachmentButton from "./download-attachment-btn";
import { mapState } from "vuex";
export default {
  // created: function() {
  //   this.$store
  //     .dispatch("messagingModule/getAttachment", {
  //       attachmentId: this.attachment.attachmentId
  //     })
  //     .then(response => {
  //       const blob = new Blob([response.data], {
  //         type: this.attachment.fileType
  //       });
  //       this.url = URL.createObjectURL(blob);
  //     });
  // },
  props: ["messageIndex", "attachmentIndex"],
  // data() {
  //   return {
  //     url: ""
  //   };
  // },
  components: {
    "download-attachment-btn": DownloadAttachmentButton
  },
  computed: {
    ...mapState("messagingModule", ["conversation"]),
    attachment: function() {
      return this.conversation.messages[this.messageIndex].attachments[
        this.attachmentIndex
      ];
    },
    fileName: function() {
      return this.attachment.fileName.substr(0, 70);
    },
    fileNameClass: function() {
      if (this.fileName.length < 25) {
        return "text-h6";
      } else {
        if (this.fileName.length < 34) {
          return "text-subtitle-1";
        } else {
          return "text-caption";
        }
      }
    },
    attachmentReadableSize: function() {
      var calculatedSizeKiloBytes = Math.round(
        this.attachment.fileSizeBytes / 1024
      );

      return (
        new Intl.NumberFormat("en-US").format(calculatedSizeKiloBytes) + "K"
      );
    },
    attachmentTypeIcon: function() {
      //based on the mime type (File.type)
      switch (this.attachment.fileExtension) {
        case "image/jpeg": //.jpg
        case "image/png": //.png
          return "file-image";
          break;
        case "application/pdf": //.pdf
          return "file-pdf";
          break;
        case "application/msword": //.doc
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": //.docx
          return "file-word";
          break;
        case "application/vnd.ms-excel": //.xls
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": //.xlsx
          return "file-excel";
          break;
        default:
          //.* file extensions
          return "";
      }
    }
    // attachmentTypeIcon: function() {
    //   //based on the extension
    //   switch (this.attachment.fileExtension) {
    //     case "jpg":
    //     case "png":
    //       return "file-image";
    //       break;
    //     case "pdf":
    //       return "file-pdf";
    //       break;
    //     case "doc":
    //     case "docx":
    //       return "file-word";
    //       break;
    //     case "xls":
    //     case "xlsx":
    //       return "file-excel";
    //       break;
    //     default:
    //       //.*
    //       return "";
    //   }
    // }
  }
};
</script>
<style>
.cardWidth {
  width: 100%;
}
</style>
