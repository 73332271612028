import Message from "../../objs/MessagingPortal/Message.ts";
import Attachment from "../../objs/MessagingPortal/Attachment.ts";
import Conversation from "../../objs/MessagingPortal/Conversation.ts";
import LightConversation from "../../objs/MessagingPortal/LightConversation.ts";
import User from "../../objs/MessagingPortal/User.ts";
import ComposeMessage from "../../objs/MessagingPortal/ComposeMessage.ts";
import Group from "../../objs/MessagingPortal/Group.ts";
import * as api from "../Services/messaging-api.js";

import * as moment from "moment";
const copyStateObjects = function copy(source, destination) {
  var keys = Object.keys(source);
  var i;
  for (i = 0; i < keys.length; i++) {
    var key = keys[i];
    var val = source[key];
    if (Array.isArray(val)) {
      destination[key] = [...val];
    } else if (val == null) {
      destination[key] = null;
    } else if (typeof val === "object" && val != null) {
      if (destination[key] == null) {
        destination[key] = val;
      } else {
        copy(val, destination[key]);
      }
    } else {
      destination[key] = source[key];
    }
  }
};
export const state = {
  action: "", //describes the component that's loaded, used for the action toolbar, options are conversation, conversation-list, compose,singleDraftConversation
  searchPhrase: "",
  selectedConversationType: "", //inbox, sent, drafts,search
  //offset: 0,
  //numberOfConversationsInSelectedType: 147, //to be used for the offset feature,
  selected: [], //expanded messages
  deleteDraftClicked: false, //can remove-not used
  selectionAction: "expand", //expand or collapse
  composeMessage: new ComposeMessage(),
  conversation: new Conversation(),
  user: new User({
    messagingUserId: 2,
    messagingAddress: "EstherVinagray@omnimessaging.com",
    displayName: "Esther Vinagray"
  }),
  userList: [],
  conversationList: [],
  searchIds: [],
  scrollToEndOfMessageList: false,
  groups: [
    new Group({
      groupId: "1234",
      name: "Software team group",
      ownerId: "2",
      members: []
    })
  ],
  group: new Group()
};
export const mutations = {
  setScrollToEndOfMessageList(state, payload) {
    state.scrollToEndOfMessageList = payload;
  },
  setMessagingUser(state, payload) {
    console.log("In set messaging user mutation");
    console.log("Payload");
    state.user = payload;
  },
  setConversationList(state, payload) {
    state.conversationList = payload;
  },

  setConversation(state, payload) {
    state.conversation = payload;
  },

  setComponentAction(state, payload) {
    state.action = payload.value;
  },
  setUserList(state, payload) {
    state.userList = payload;
  },
  setSearchPhrase(state, payload) {
    state.searchPhrase = payload.value;
  },
  clearSearchPhrase(state) {
    state.searchPhrase = "";
  },
  setSearchIds(state, payload) {
    state.searchIds = payload;
  },
  clearSearchIds(state) {
    state.searchIds = [];
  },
  clearConversation(state) {
    state.conversation = new Conversation();
  },
  deleteMessageAtIndex(state, payload) {
    state.conversation.messages.splice(payload, 1);
  },
  setDeleteDraftClicked(state, payload) {
    state.deleteDraftClicked = payload;
  },

  setSelectedConversationType(state, payload) {
    state.selectedConversationType = payload.value;
  },
  addAttachment(state, payload) {
    state.conversation.messages[payload.messageIndex].attachments.push(
      new Attachment({
        file: payload.file,
        fileExtension: payload.file.type,
        fileName: payload.file.name,
        fileSizeBytes: payload.file.size
      })
    );
  },
  removeAttachment(state, payload) {
    state.conversation.messages[payload.index].attachments.splice(
      payload.subIndex,
      1
    );
  },
  addMessage(state, payload) {
    state.conversation.messages.push(payload);
  },

  // increaseOffset(state) {
  //   state.offset = state.offset + 50;
  // },
  // decreaseOffset(state) {
  //   state.offset = state.offset - 50;
  // },
  // resetOffset(state) {
  //   state.offset = 0;
  // },
  // setNumberOfConversationsInSelectedType(state) {
  //   state.numberOfConversationsInSelectedType =
  //     state.responseObject.data.numberOfConversations;
  // },
  changeSelectedMessage(state) {
    if (state.selectionAction == "expand") {
      state.selected = [
        ...Array(state.conversation.messages.length).keys()
      ].map((k, i) => i);
    } else {
      state.selected = [];
    }
  },
  resetSelectionAction(state) {
    state.selectionAction = "expand";
  },
  toggleSelectionAction(state) {
    if (state.selectionAction == "expand") {
      state.selectionAction = "collapse";
    } else {
      state.selectionAction = "expand";
    }
  },

  setMessageId(state, payload) {
    state.conversation.messages[payload.index].messageId = payload.messageId;
    state.composeMessage.conversationId = payload.conversationId;
  },
  setConversationId(state, payload) {
    state.conversation.conversationId = payload.conversationId;
  },
  setMessage(state, payload) {
    copyStateObjects(
      payload.message,
      state.conversation.messages[payload.index]
    );
    //state.conversation.messages[payload.index] = payload.message;
  },
  buildComposeMessageObject(state, payload) {
    let message = state.conversation.messages[payload.index];
    let recipientIdList = [];
    let i;
    let j;
    for (i = 0; i < message.recipients.length; i++) {
      var recipient = message.recipients[i];
      if (recipient.groupId != null) {
        ///appendGroupMembersToRecipientList
        for (j = 0; j < recipient.members.length; j++) {
          recipientIdList.push(recipient.members[j].messagingUserId);
        }
      } else {
        recipientIdList.push(recipient.messagingUserId);
      }
    }
    //remove duplicates
    //console.log("with duplicates: " + recipientIdList);
    let recipientIds = [...new Set(recipientIdList)];
    // console.log("after duplicates removed: " + recipientIds);
    // console.log("recipient ids" + recipientIds);
    let newMessage = new ComposeMessage({
      senderId: state.user.messagingUserId,
      content: message.content,
      subject: state.conversation.subject,
      messageId: message.messageId,
      conversationId: state.conversation.conversationId,
      recipientIds: recipientIds
    });
    state.composeMessage = newMessage;
  },
  removeConversationAtIndex(state, payload) {
    state.conversationList.splice(payload.index, 1);
  },
  replaceConversationAtIndex(state, payload) {
    copyStateObjects(
      payload.flatConversation,
      state.conversationList[payload.index]
    );
  },
  addConversation(state, payload) {
    //add conversation to the beginning of the array
    state.conversationList.unshift(payload);
  },
  addForwardedAttachment(state, payload) {
    state.conversation.messages[payload.messageIndex].attachments.push(
      payload.attachment
    );
  },
  setLatestDraftDate(state, payload) {
    state.conversationList[
      payload.index
    ].latestDraftDate = new Date().toISOString();
  },
  setGroups(state, payload) {
    state.groups = payload;
  },
  deleteGroup(state, index) {
    state.groups.splice(index, 1);
  },

  addGroup(state, payload) {
    state.groups.unshift(payload);
  },
  updateGroup(state, payload) {
    state.groups[payload.index] = payload.group;
  },
  clearGroup: state => {
    state.group = new Group();
  },
  setGroup: (state, group) => {
    state.group = group;
  }
};
export const getters = {
  getConversationIndexForId: state => id => {
    var cs = state.conversationList;
    var i;
    var index;
    for (i = 0; i < cs.length; i++) {
      if (cs[i].conversationId == id) {
        index = i;
        break;
      }
    }
    return index;
  },
  getUserList: state => {
    return state.userList;
  },
  getMessagesForSelectedConversation: (state, getters) => {
    switch (state.selectedConversationType) {
      case "Inbox":
        return getters.getInbox;
        break;
      case "Sent":
        return getters.getSent;
        break;
      case "Drafts":
        return getters.getDrafts;
        break;
      case "Search":
        return getters.getSearchResults;
        break;
    }
  },
  getLastMessageIndex: state => {
    return state.conversation.messages.length - 1;
  },
  getInbox: state => {
    var conversations = state.conversationList;
    return conversations.filter(function(flatConversation) {
      return flatConversation.isInInbox;
    });
  },
  getSent: state => {
    var conversations = state.conversationList;
    return conversations.filter(function(flatConversation) {
      return flatConversation.isInSent == true;
    });
  },
  getDrafts: state => {
    //
    var drafts = state.conversationList.filter(function(flatConversation) {
      return flatConversation.isInDrafts == true;
    });
    return drafts.sort(function(a, b) {
      return b.latestDraftDate < a.latestDraftDate
        ? -1
        : b.latestDraftDate > a.latestDraftDate
        ? 1
        : 0;
    });
  },
  getSearchResults: state => {
    //change
    var searchIds = state.searchIds;
    var conversations = state.conversationList;
    return conversations.filter(function(flatConversation) {
      return searchIds.includes(flatConversation.conversationId);
    });
  },
  getGroupIndexById: state => id => {
    console.log("In getgroupindexbyid, id is " + id);
    var gs = state.groups;
    var i;
    var index;
    for (i = 0; i < gs.length; i++) {
      if (gs[i].groupId == id) {
        index = i;
        break;
      }
    }
    return index;
  },
  getAllGroupNames: state => {
    var names = [];
    var i;
    for (i = 0; i < state.groups.length; i++) {
      names.push(state.groups[i].name);
    }
    return names;
  },

  getAllOtherGroupNames: state => group => {
    var names = [];
    var i;
    for (i = 0; i < state.groups.length; i++) {
      if (group.groupId != state.groups[i].groupId) {
        names.push(state.groups[i].name);
      }
    }
    return names;
  }
};
export const actions = {
  async getMessagingUser({ commit, state, rootState }) {
    console.log("Getting messaging user");
    var userId = rootState.securityModule.user.profile.sub;
    console.log("User id is " + userId);
    return api.getMessagingUser(userId).then(response => {
      console.log(response);
      commit("setMessagingUser", response.data);
    });
  },

  async getUsers({ commit, state }) {
    console.log("Getting users action");
    return api.getMessagingUsers().then(response => {
      commit("setUserList", response.data);
    });
  },
  async loadConversationList({ commit, state, dispatch }) {
    return api
      .getConversationList(state.user.messagingUserId)
      .then(response => {
        commit("setConversationList", response.data);
      });
  },
  async loadConversation({ commit, state }, payload) {
    return api
      .getConversation(state.user.messagingUserId, payload.conversationId)
      .then(response => {
        commit("setConversation", response.data);
      });
  },
  async search({ commit, state }) {
    console.log("getting search action");
    return api
      .search(
        state.user.messagingUserId,
        state.searchPhrase
        //, offset: state.offset
      )
      .then(response => {
        commit("setSearchIds", response.data);
        //commit("setNumberOfConversationsInSelectedType");
      });
  },

  async deleteDraft({ commit, state, dispatch, getters }, payload) {
    console.log("in delete message action");
    var messageIndex = payload.index;
    var conversationIndex = getters.getConversationIndexForId(
      state.conversation.conversationId
    );

    var id = state.conversation.messages[payload.index].messageId;
    if (id == 0) {
      commit("deleteMessageAtIndex", messageIndex);
      return;
    } else {
      commit("uxModule/setShowSnackbar", false, { root: true });
      return api.deleteDraft(id).then(response => {
        if (response.success) {
          commit("uxModule/setSnackbarMsg", "Draft deleted", {
            root: true
          });
          commit("uxModule/setShowSnackbar", true, { root: true });
          commit("deleteMessageAtIndex", messageIndex);
          if (response.data == null) {
            commit("removeConversationAtIndex", { index: conversationIndex });
          } else {
            commit("replaceConversationAtIndex", {
              index: conversationIndex,
              flatConversation: response.data
            });
          }
        } else {
          commit("uxModule/setSnackbarMsg", response.message, {
            root: true
          });
          commit("uxModule/setShowSnackbar", true, { root: true });
        }
      });
    }
  },
  async deleteConversation({ commit, state, dispatch, getters }, payload) {
    console.log("in delete conversation action");
    var conversationIndex = getters.getConversationIndexForId(
      payload.conversationId
    );
    console.log("conversation index " + conversationIndex);
    commit("uxModule/setShowSnackbar", false, { root: true });
    return api
      .deleteConversation(state.user.messagingUserId, payload.conversationId)
      .then(response => {
        commit("uxModule/setSnackbarMsg", "Conversation Deleted", {
          root: true
        });
        commit("uxModule/setShowSnackbar", true, { root: true });
        commit("removeConversationAtIndex", { index: conversationIndex });
      });
  },

  async markConversationAsRead({ commit, state, dispatch, getters }, payload) {
    var conversationIndex = getters.getConversationIndexForId(
      payload.conversationId
    );

    return api
      .markAsRead(state.user.messagingUserId, payload.conversationId)
      .then(response => {
        if (response.success) {
          commit("replaceConversationAtIndex", {
            index: conversationIndex,
            flatConversation: response.data
          });
        }
      });
  },
  async updateDraftMessage({ commit, state, dispatch, getters }, payload) {
    console.log("update draft message");
    var conversationIndex = getters.getConversationIndexForId(
      state.conversation.conversationId
    );
    commit("buildComposeMessageObject", { index: payload.index });
    commit("uxModule/setShowSnackbar", false, { root: true });
    return api.updateDraftMessage(state.composeMessage).then(response => {
      if (response.success) {
        commit("setMessage", {
          index: payload.index,
          message: response.data
        }); //sets messageId and isDraft to false

        commit("setLatestDraftDate", { index: conversationIndex });
        commit("uxModule/setSnackbarMsg", "Draft Saved", {
          root: true
        });
        commit("uxModule/setShowSnackbar", true, { root: true });
      }
    });
  },

  async createNewMessage({ commit, state, dispatch, getters }, payload) {
    commit("buildComposeMessageObject", { index: payload.index });
    if (state.composeMessage.conversationId == 0) {
      return api
        .createNewMessageInNewConversation(state.composeMessage)
        .then(response => {
          if (response.success) {
            commit("setMessageId", {
              index: payload.index,
              messageId: response.data.message.messageId
            });
            commit("setConversationId", {
              conversationId: response.data.conversation.conversationId
            });
            commit("addConversation", response.data.conversation);
          } else {
            commit("uxModule/setShowSnackbar", false, { root: true });
            commit("uxModule/setSnackbarMsg", response.message, {
              root: true
            });
            commit("uxModule/setShowSnackbar", true, { root: true });
          }
        });
    } else {
      var conversationIndex = getters.getConversationIndexForId(
        state.conversation.conversationId
      );
      return api
        .createNewMessageInExistingConversation(state.composeMessage)
        .then(response => {
          if (response.success) {
            commit("setMessageId", {
              index: payload.index,
              messageId: response.data.message.messageId
            });
            commit("removeConversationAtIndex", { index: conversationIndex });
            commit("addConversation", response.data.conversation);
          } else {
            commit("uxModule/setShowSnackbar", false, { root: true });
            commit("uxModule/setSnackbarMsg", response.message, {
              root: true
            });
            commit("uxModule/setShowSnackbar", true, { root: true });
          }
        });
    }
  },
  async sendMessage({ commit, state, dispatch, getters }, payload) {
    var conversationIndex = getters.getConversationIndexForId(
      state.conversation.conversationId
    );
    commit("buildComposeMessageObject", { index: payload.index });
    commit("uxModule/setShowSnackbar", false, { root: true });
    commit("uxModule/setShowLoader", true, { root: true });
    return api.sendMessage(state.composeMessage).then(response => {
      console.log("response success" + response.success);
      if (response.success) {
        commit("setMessage", {
          index: payload.index,
          message: response.data.message
        }); //sets messageId and isDraft to false
        commit("removeConversationAtIndex", { index: conversationIndex });
        commit("addConversation", response.data.conversation);

        commit("uxModule/setShowLoader", false, { root: true });
        commit("uxModule/setSnackbarMsg", "Message Sent", {
          root: true
        });
        commit("uxModule/setShowSnackbar", true, { root: true });
      } else {
        commit("uxModule/setShowLoader", false, { root: true });

        commit("uxModule/setSnackbarMsg", response.message, {
          root: true
        });
        commit("uxModule/setShowSnackbar", true, { root: true });
      }
    });
  },
  async uploadAttachmentsForMessage({ commit, state, dispatch }, payload) {
    console.log("upload attachments for message");
    var attachments = state.conversation.messages[payload.index].attachments;
    //for each
    var i;
    for (i = 0; i < attachments.length; i++) {
      await dispatch("uploadAttachment", {
        index: payload.index,
        attachmentIndex: i
      });
    }
    return;
  },
  async uploadAttachment({ commit, state }, payload) {
    var messageId = state.conversation.messages[payload.index].messageId;
    console.log(
      "payload attachment index is " +
        payload.attachmentIndex +
        "message index is " +
        payload.index
    );
    var attachment =
      state.conversation.messages[payload.index].attachments[
        payload.attachmentIndex
      ];
    console.log("attachment is " + attachment);
    if (attachment.attachmentId == 0) {
      var formData = new FormData();
      var file = new File([attachment.file], attachment.fileName, {
        type: attachment.fileExtension
      });
      formData.append("messageId", messageId);
      formData.append("asset", file);

      return api.addAttachmentToMessage(formData).then(response => {});
    } else {
      return;
    }
  },
  async getAttachment({ commit, state }, payload) {
    return api.getAttachment(payload.fileId).then(response => {
      console.log("response in get attachmnet " + response);
      return response;
    });
  },
  async removeAttachmentFromMessage({ commit, state }, payload) {
    var attachment =
      state.conversation.messages[payload.messageIndex].attachments[
        payload.attachmentIndex
      ];
    return api.removeAttachmentFromMessage(attachment).then(response => {
      console.log("attachment removed");
    });
  },
  replyToMessage({ commit, state }, payload) {
    console.log("in reply action index is " + payload.messageIndex);
    let sender = state.conversation.messages[payload.messageIndex].sender;
    let newMessage = new Message();
    newMessage.recipients.push(sender);
    commit("addMessage", newMessage);
    commit("setScrollToEndOfMessageList", true);
  },
  replyToAll({ commit, state }, payload) {
    let sender = state.conversation.messages[payload.messageIndex].sender;
    let recipients =
      state.conversation.messages[payload.messageIndex].recipients;
    let newMessage = new Message();
    if (!(state.user.messagingUserId == sender.messagingUserId)) {
      newMessage.recipients.push(sender);
    }

    let i;
    for (i = 0; i < recipients.length; i++) {
      if (state.user.messagingUserId == recipients[i].messagingUserId) {
        continue;
      }
      newMessage.recipients.push(recipients[i]);
    }
    commit("addMessage", newMessage);
    commit("setScrollToEndOfMessageList", true);
  },
  async forwardMessage({ commit, state, dispatch, getters }, payload) {
    //create message with content of ("appendConversationIntoNewMessage");
    //then async  create message
    //getId and upload each attachment on response, place the attachment vm at that position in the convo,message,attachment position
    await dispatch("appendConversationIntoNewMessage", {
      messageIndex: payload.messageIndex
    });
    var newMessageIndex = getters.getLastMessageIndex;
    await dispatch("createNewMessage", {
      index: newMessageIndex
    }).then(response => {
      dispatch("forwardAttachments", {
        forwardedMessageIndex: payload.messageIndex,
        newMessageIndex: newMessageIndex
      });
    });
  },

  async appendConversationIntoNewMessage({ commit, state, dispatch }, payload) {
    let messageListAsString = "";
    let i;
    for (i = payload.messageIndex; i >= 0; i--) {
      if (state.conversation.messages[i].isDraft) {
        continue;
      }
      messageListAsString += buildMessageObjectAsString(
        state.conversation.messages[i]
      );

      messageListAsString += "<hr>";
    }
    let content =
      "<br/><hr>************Forwarded Message************" +
      messageListAsString;

    let newMessage = new Message({
      content: content
    });
    commit("addMessage", newMessage);
    commit("setScrollToEndOfMessageList", true);
  },
  async forwardAttachments({ commit, state, dispatch }, payload) {
    var message = state.conversation.messages[payload.newMessageIndex];
    var attachmentList =
      state.conversation.messages[payload.forwardedMessageIndex].attachments;
    let i;
    for (i = 0; i < attachmentList.length; i++) {
      await dispatch("forwardAttachment", {
        fileId: attachmentList[i].fileId,
        messageId: message.messageId
      }).then(response => {
        var newAttachment = new Attachment();
        newAttachment.attachmentId = response.data.attachmentId;
        newAttachment.fileId = attachmentList[i].fileId;
        newAttachment.fileName = attachmentList[i].fileName;
        newAttachment.fileExtension = attachmentList[i].fileExtension;
        newAttachment.fileSizeBytes = attachmentList[i].fileSizeBytes;
        commit("addForwardedAttachment", {
          messageIndex: payload.newMessageIndex,
          attachment: newAttachment
        });
      });
    }
  },
  async forwardAttachment({ commit, state, dispatch }, payload) {
    return api.forwardAttachment(payload.fileId, payload.messageId);
  },
  changeSelection({ commit }) {
    commit("changeSelectedMessage");
    commit("toggleSelectionAction");
  },

  async createGroup({ commit, state }) {
    state.group.ownerId = state.user.messagingUserId;
    return api.createGroup(state.group).then(response => {
      if (response.success) {
        commit("uxModule/setShowLoader", false, { root: true });
        commit("uxModule/setSnackbarMsg", "Group successfuly saved", {
          root: true
        });
        commit("uxModule/setShowSnackbar", true, { root: true });
        commit("addGroup", response.data);
      } else {
        commit("uxModule/setShowLoader", false, { root: true });

        commit("uxModule/setSnackbarMsg", response.message, {
          root: true
        });
        commit("uxModule/setShowSnackbar", true, { root: true });
      }
    });
  },

  async updateGroup({ commit, state, getters }) {
    return api.updateGroup(state.group).then(response => {
      if (response.success) {
        commit("uxModule/setShowLoader", false, { root: true });
        commit("uxModule/setSnackbarMsg", "Group saved", {
          root: true
        });
        commit("uxModule/setShowSnackbar", true, { root: true });
        var index = getters.getGroupIndexById(state.group.groupId);
        commit("updateGroup", { index: index, group: response.data });
      } else {
        commit("uxModule/setShowLoader", false, { root: true });
        commit("uxModule/setSnackbarMsg", response.message, {
          root: true
        });
        commit("uxModule/setShowSnackbar", true, { root: true });
      }
    });
  },

  async deleteGroup({ commit, state, getters }) {
    commit("uxModule/setShowSnackbar", false, { root: true });
    var index = getters.getGroupIndexById(state.group.groupId);
    console.log("index" + index);
    return api.deleteGroup(state.group.groupId).then(response => {
      if (response.success) {
        commit("uxModule/setSnackbarMsg", "Group deleted", {
          root: true
        });
        commit("uxModule/setShowSnackbar", true, { root: true });
        commit("deleteGroup", index);
      } else {
        commit("uxModule/setSnackbarMsg", response.message, {
          root: true
        });
        commit("uxModule/setShowSnackbar", true, { root: true });
      }
    });
  },
  async getMessagingGroups({ commit, state }) {
    console.log("in getmessaging groups");
    console.log("messaginguserid is " + state.user.messagingUserId);
    return api.getMessagingGroups(state.user.messagingUserId).then(response => {
      console.log(response);
      commit("setGroups", response.data);
    });
  }
};

function buildMessageObjectAsString(messageToForward) {
  let sender, dateSent, subject, to, attachments;
  sender = messageToForward.sender.displayName;
  dateSent = moment
    .utc(messageToForward.sentAt)
    .tz("America/New_York")
    .format("ddd, MMMM D, h:mm A");
  subject = state.conversation.subject;
  to = "";
  let i;
  for (i = 0; i < messageToForward.recipients.length; i++) {
    to += messageToForward.recipients[i].displayName;

    if (i < messageToForward.recipients.length - 1) {
      to += ", ";
    }
  }

  let content =
    "<p>From: " +
    sender +
    "</p>" +
    "<p>Date: " +
    dateSent +
    "</p>" +
    "<p>Subject: " +
    subject +
    "</p>" +
    "<p>To: " +
    to +
    "</p><br/>";
  content += messageToForward.content;
  return content;
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
