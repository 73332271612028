<template>
  <div>
    <actions-toolbar></actions-toolbar> <v-divider></v-divider>

    <v-row align="center">
      <v-col class="my-2 d-flex">
        <span class="headline ml-2">{{ conversation.subject }}</span>
        <v-spacer></v-spacer>
        <expand-collapse-messages></expand-collapse-messages>
      </v-col>
    </v-row>
    <div>
      <message-list-container></message-list-container>
    </div>
  </div>
</template>

<script>
import ActionsToolbar from "../../ActionToolbar/actions-toolbar";

import ExpandCollapseMessages from "./expand-collapse-messages";
import MessageListContainer from "./message-list-container";
export default {
  created: function() {
    this.$store.commit("messagingModule/setComponentAction", {
      value: "conversation"
    });
    this.$store.commit("messagingModule/setDeleteDraftClicked", false);
  },
  components: {
    "expand-collapse-messages": ExpandCollapseMessages,
    "message-list-container": MessageListContainer,
    "actions-toolbar": ActionsToolbar
  },

  computed: {
    conversation: function() {
      return this.$store.state.messagingModule.conversation;
    },
    containsMultipleMessages: function() {
      return this.$store.state.messagingModule.conversation.messages.length > 1;
    }
  }
};
</script>
