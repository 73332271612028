<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark color="#88acd4" fab x-small @click="changeSelection" v-bind="attrs" v-on="on"
        ><icon class="fa-lg mr-1" :icon="actionIcon"></icon
      ></v-btn>
    </template>
    <span>{{ actionName }}</span>
  </v-tooltip>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("messagingModule", { action: "selectionAction" }),
    actionIcon: function() {
      if (this.action == "expand") {
        return "expand-alt";
      }
      if (this.action == "collapse") {
        return "compress-alt";
      }
    },
    actionName: function() {
      if (this.action == "expand") {
        return "Expand All";
      }
      if (this.action == "collapse") {
        return "Collapse All";
      }
    }
  },
  methods: {
    changeSelection: function() {
      this.$store.dispatch("messagingModule/changeSelection");
    }
  }
};
</script>

<style></style>
