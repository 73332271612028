<template>
  <v-list-item>
    <v-list-item-content v-on:click="selectConversation">
      <v-list-item-title>
        <span :class="boldClass">{{ participants }}</span>
        <span class="red--text" v-if="containsDraft"> draft</span>
        <span> {{ numberOfMessages }}</span>
      </v-list-item-title>
      <v-list-item-subtitle
        ><span class="subject">{{
          conversationItem.subject
        }}</span></v-list-item-subtitle
      >
      <v-list-item-subtitle v-text="preview"></v-list-item-subtitle>
      <v-list-item-subtitle v-if="conversationItem.hasAttachments">
        <icon color="grey" icon="paperclip"></icon>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-list-item-action-text>
        <span :class="boldClass" style="color:black">{{
          formattedDate
        }}</span></v-list-item-action-text
      >

      <delete-conversation-model
        type="Conversation"
        :id="conversationItem.conversationId"
        :index="index"
      ></delete-conversation-model>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import DeleteConversationMessageModel from "../delete-conversation-message-model";
import { mapActions } from "vuex";
import * as moment from "moment";
import * as momentTimezone from "moment-timezone";
export default {
  props: ["conversationItem", "index"],
  components: {
    "delete-conversation-model": DeleteConversationMessageModel
  },
  computed: {
    preview: function() {
      if (this.conversationItem.preview != null) {
        var reg = new RegExp("\\<.*?\\>", "g");
        return this.conversationItem.preview.replace(reg, "");
      } else {
        return "";
      }
    },
    containsDraft: function() {
      if (this.conversationItem.isInDrafts) {
        return true;
      } else {
        return false;
      }
    },
    numberOfMessages: function() {
      return this.conversationItem.numMessages;
    },
    isUnread: function() {
      return !this.conversationItem.isRead;
    },
    selectedConversationType: function() {
      return this.$store.state.messagingModule.selectedConversationType;
    },
    isInbox: function() {
      return this.selectedConversationType == "Inbox";
    },
    isUnreadIncomingMessage: function() {
      return this.isUnread && this.isInbox;
    },
    boldClass: function() {
      if (this.isUnreadIncomingMessage) return "font-weight-bold";
      return "font-weight-regular";
    },
    // boldDate: function() {
    //   var today = new Date();
    //   if (moment(today).isSame(this.conversationDate, "day")) {
    //     return "font-weight-bold";
    //   } else return "font-weight-regular";
    // },

    participants: function() {
      if (this.conversationItem.participants.length > 1) {
        return this.concatNamesList();
      } else {
        return this.conversationItem.participants[0];
      }
    },
    conversationDate: function() {
      return moment.utc(this.conversationItem.date).tz("America/New_York");
    },
    formattedDate: function() {
      var today = new Date();

      if (moment(today).isSame(this.conversationDate, "day")) {
        return moment(this.conversationDate).format("h:mm A");
      }
      if (moment(today).isSame(this.conversationDate, "year")) {
        return moment(this.conversationDate).format("MMM D");
      }
      return moment(this.conversationDate).format("MM/DD/YY");
    }
  },
  methods: {
    ...mapActions("messagingModule", [
      "loadConversation",
      "markConversationAsRead",
      "deleteConversation"
    ]),
    concatNamesList: function() {
      var names = "";
      var i;
      for (i = 0; i < this.conversationItem.participants.length; i++) {
        names += this.getReplacementName(this.conversationItem.participants[i]);

        if (i + 1 < this.conversationItem.participants.length) {
          names += ", ";
        }
      }

      return names;
    },

    getReplacementName: function(name) {
      if (name == this.$store.state.messagingModule.user.displayName)
        return "me";
      else return name;
    },
    selectConversation: function() {
      if (this.isUnreadIncomingMessage) {
        this.markConversationAsRead({
          conversationId: this.conversationItem.conversationId
        });
      }
      this.$store.commit("messagingModule/resetSelectionAction");
      this.loadConversation({
        conversationId: this.conversationItem.conversationId
      }).then(response => {
        if (this.containsDraft && this.numberOfMessages == 1) {
          this.$router.push({ name: "DraftContainer" });
        } else {
          this.$router.push({ name: "Conversation" });
        }
      });
    }
  }
};
</script>

<style>
.subject {
  color: #486081;
}
</style>
