<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1100" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="!newGroup"
          title="Edit group"
          text
          v-on="on"
          v-on:click="activateDialog"
          ><icon icon="pen" color="#88acd4"
        /></v-btn>
        <v-btn
          v-if="newGroup"
          v-on="on"
          v-bind="attrs"
          v-on:click="activateDialog"
          color="#1D3557"
          dark
        >
          <icon icon="users" class="mr-3" />New Group
        </v-btn>
      </template>

      <v-card class="mx-auto">
        <v-card-title>Group</v-card-title>

        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="4">
                <form-field
                  class="mx-4"
                  :field="fields.name"
                  :validations="$v.values.name"
                ></form-field
              ></v-col> </v-row
            ><v-row
              ><v-col cols="12">
                <form-field
                  class="mx-4"
                  :field="fields.members"
                  :validations="$v.values.members"
                ></form-field
              ></v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-grey" dark outlined @click="cancel">Cancel</v-btn>
          <v-btn color="blue-grey" dark @click="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import FormField from "../../objs/Shared/FormField";
import { FieldTypes } from "../../objs/Shared/FieldTypes";
import { mapState } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
const distinct = param => value => {
  if (value === "") {
    return true;
  }
  return !param.includes(value);
};

export default {
  props: ["group", "newGroup"],
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapState("messagingModule", ["userList"]),

    fields: function() {
      let fields = {
        name: new FormField({
          moduleName: "messagingModule",
          tier: 1,
          objName: "group",
          propName: "name",
          componentName: FieldTypes.TEXT_FIELD,
          label: "Name"
        }),
        members: new FormField({
          tier: 1,
          moduleName: "messagingModule",
          objName: "group",
          propName: "members",
          componentName: FieldTypes.MULTIPLE_AUTOCOMPLETE,
          options: this.userList,
          itemText: "messagingAddress",
          itemValue: "messagingUserId",
          label: "Members"
        })
      };
      return fields;
    },
    values: function() {
      var name = this.$store.state.messagingModule.group.name;
      var members = this.$store.state.messagingModule.group.members;

      var vals = {
        name: name,
        members: members
      };
      return vals;
    },
    allGroupNames: function() {
      if (this.newGroup) {
        return this.$store.getters["messagingModule/getAllGroupNames"];
      } else {
        return this.$store.getters["messagingModule/getAllOtherGroupNames"](
          this.group
        );
      }
    },
    groupIndex: function() {
      return this.$store.getters["messagingModule/getGroupIndexById"](
        this.group.groupId
      );
    }
  },
  validations() {
    let vals = {
      name: {
        required: required,
        distinct: distinct(this.allGroupNames)
      },
      members: { required: required, minLength: minLength(1) }
    };
    return { values: vals };
  },
  methods: {
    activateDialog: function() {
      if (this.newGroup) {
        this.$store.commit("messagingModule/clearGroup");
      } else {
        this.$store.commit("messagingModule/setGroup", this.group);
      }
      this.dialog = true;
    },
    submit: function() {
      this.$v.values.$touch();
      var valid = !this.$v.$invalid;
      if (valid) {
        if (this.newGroup) {
          this.$store.dispatch("messagingModule/createGroup");
        } else {
          this.$store.dispatch("messagingModule/updateGroup");
        }
        this.$v.values.$reset();
        this.dialog = false;
      } else {
        console.log("validations r invalid");
      }
    },
    cancel: function() {
      this.$v.values.$reset();
      this.$store.commit("messagingModule/clearGroup");
      this.dialog = false;
    }
  }
};
</script>
