<template>
  <div>
    <v-dialog v-model="dialog" max-width="350">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon @click="toggleDialog">
          <icon color="#88acd4" icon="trash-alt"></icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline">Delete {{ type }}</v-card-title>
        <v-card-text
          >Are you sure you want to delete this {{ type }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="blue-grey" dark @click="toggleDialog"
            >Cancel</v-btn
          >
          <v-btn color="blue-grey" dark @click="deleteByType">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["type", "id", "index"],
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    action: function() {
      return this.$store.state.messagingModule.action;
    },
    conversationId: function() {
      return this.$store.state.messagingModule.conversation.conversationId;
    }
  },
  methods: {
    ...mapActions("messagingModule", ["deleteConversation", "deleteDraft"]),

    deleteByType: function() {
      this.toggleDialog();
      if (this.type === "Conversation") {
        this.deleteConversation({
          conversationId: this.id,
          index: this.index
        });
      }
      if (this.type === "Message") {
        this.deleteDraft({
          index: this.index
        }).then(response => {
          if (
            this.action == "compose" ||
            this.action == "singleDraftConversation"
          ) {
            this.$router.push({ name: "Inbox" });
          }
        });
      }
    },

    toggleDialog: function() {
      if (this.dialog == false) {
        this.dialog = true;
        return;
      }
      if (this.dialog == true) {
        this.dialog = false;
        return;
      }
    }
  }
};
</script>

<style></style>
