<template>
  <v-app id="app">
    <toolbar></toolbar>
    <nav-drawer></nav-drawer>

    <v-content>
      <v-container fluid>
        <v-layout align-center justify-left>
          <div class="container">
            <snackbar></snackbar>
            <loader></loader>
            <search-bar></search-bar>

            <router-view v-show="showRouterView"></router-view>
            <help style="position:fixed; bottom:40px; right: 25px;"></help>
          </div>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import SearchBar from "./components/SearchBar/search-bar";
import Toolbar from "./components/AppBar/toolbar";
import NavDrawer from "./components/NavigationBar/nav-drawer";
import Snackbar from "./components/Shared/snackbar";
import Loader from "./components/Shared/loader";
import Help from "./components/Shared/help";
export default {
  name: "app",
  components: {
    toolbar: Toolbar,
    navDrawer: NavDrawer,
    snackbar: Snackbar,
    loader: Loader,

    "search-bar": SearchBar,
    help: Help
  },
  created: async function() {
    //console.log("In the created function");
    //console.log(this.$store.state.securityModule.user);
    //if (this.$store.state.securityModule.user.profile.sub != null)
    //{
    //  await this.$store.dispatch("messagingModule/getMessagingUser");
    //
    //}
  },
  mounted: async function() {
    //console.log("In the mounted function");
    //      console.log(this.$store.state.securityModule.user);
    //     if (this.$store.state.securityModule.user.profile.sub != null)
    //{
    //  await this.$store.dispatch("messagingModule/getMessagingUser");
    //  await this.$store.dispatch("messagingModule/getUsers");
    //  await this.$store.dispatch("messagingModule/loadConversationList");
    //}
    //console.log("in mounted");
    //console.log(this.$store.state.messagingModule.user);
  },
  computed: {
    showRouterView: function() {
      var requiresAuth = this.$router.currentRoute.meta.requiresAuth;
      return (
        !requiresAuth ||
        (this.$store.state.securityModule.requiresAuth &&
          this.$store.state.securityModule.isAuthenticated)
      );
    }
  }
};
</script>
<style>
.content {
  padding: 0px 0px;
}
</style>
