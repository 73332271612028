<template>
  <v-btn
    rounded
    outlined
    color="black"
    class="mt-2"
    v-on:click="composeNewMessage"
  >
    <icon icon="pen" class="mr-2" color="#1D3557"></icon
    ><span class="ml-1 lowercase">Compose</span></v-btn
  >
</template>
<script>
export default {
  methods: {
    composeNewMessage: function() {
      this.$store.commit("messagingModule/clearConversation");
      this.$store.commit("messagingModule/clearSearchPhrase");
      this.$store.commit("messagingModule/clearSearchIds");
      this.$router.push({ name: "Compose", params: { index: 0 } });
    }
  }
};
</script>
<style>
.lowercase {
  text-transform: none !important;
}
</style>
