<template>
  <v-row align="center"
    ><v-col cols="9"> <back-btn> </back-btn> </v-col
  ></v-row>
</template>

<script>
import BackButton from "./back-btn";
export default {
  components: {
    "back-btn": BackButton
  }
};
</script>
<style></style>
