<template>
  <v-row align="center">
    
      <span class="headline ml-4">{{ conversationType }}</span>
      <v-spacer></v-spacer>
      <refresh-btn></refresh-btn>
    
    <!-- <v-col cols="2" class="d-flex justify-end"
      ><conversation-list-offset></conversation-list-offset
    ></v-col> -->
    <!-- <v-col cols="2"> <offset-actions></offset-actions> </v-col> -->
  </v-row>
</template>
<script>
import RefreshButton from "./refresh-btn";
// import ConversationListOffset from "./conversation-list-offset-list-length";

// import OffsetActions from "./conversation-list-offset-actions";

export default {
  computed: {
    conversationType: function() {
      return this.$store.state.messagingModule.selectedConversationType;
    }
  },
  components: {
    // "conversation-list-offset": ConversationListOffset,
    "refresh-btn": RefreshButton
    //"offset-actions": OffsetActions
  }
};
</script>
