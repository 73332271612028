import axios from "axios";
//var urlPrefix = "https://localhost:5001";
var urlPrefix = "https://api.omniuserportal.com";

export async function sendFeedbackEmail(data) {
  return axios
    .post(urlPrefix + "/api/shared/sendFeedbackEmail", data)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}
export async function get(payload) {
  return axios
    .get(urlPrefix + "/api/get", {
      params: {}
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log("Error " + error);
      return error;
    });
}
export async function post(data) {
  console.log("in create new message new convo");
  return axios
    .post(urlPrefix + "/api/", data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log("error" + error);
      return error;
    });
}
export async function postQueryString(payload) {
  return axios
    .post(urlPrefix + "/api/", null, {
      params: {}
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
