export default class Attachment {
  attachmentId?: number | null;
  fileId?: number | null;
  file?: File | null;
  fileExtension?: string | null;
  fileName?: string | null;
  fileSizeBytes?: string | null;
  public constructor(params: Attachment = {} as Attachment) {
    let {
      attachmentId = 0,
      fileId = 0,
      file = null,
      fileExtension = "",
      fileName = "",
      fileSizeBytes = ""
    } = params;
    this.attachmentId = attachmentId;
    this.fileId = fileId;
    this.file = file;
    this.fileExtension = fileExtension;
    this.fileName = fileName;
    this.fileSizeBytes = fileSizeBytes;
  }
}
