<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on }">
        <v-btn title="Delete group" text v-on="on" v-on:click="activateDialog"
          ><icon icon="trash-alt" color="#88acd4"
        /></v-btn>
      </template>

      <v-card class="mx-auto">
        <v-card-title>Delete Group</v-card-title>

        <v-card-text>
          <p>Do you want to delete the group {{ name }}?</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="blue-grey" dark @click="cancel">Cancel</v-btn>
          <v-btn color="blue-grey" dark @click="deleteGroup"
            >Delete Group</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["group"],
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    name: function() {
      return this.group.name;
    }
  },
  methods: {
    activateDialog: function() {
      this.$store.commit("messagingModule/setGroup", this.group);
      this.dialog = true;
    },
    deleteGroup: async function() {
      var succeeded = await this.$store.dispatch("messagingModule/deleteGroup");
      this.dialog = false;
    },
    cancel: function() {
      this.$store.commit("messagingModule/clearGroup");
      this.dialog = false;
    }
  }
};
</script>
