<template>
  <div>
    <div v-if="isDraft" class="n-msg-pl elevation-12">
      <message-editable :index="index"></message-editable>
    </div>
    <div v-else>
      <message-display :index="index"></message-display>
    </div>
  </div>
</template>
<script>
import MessageEditable from "../DisplayMessage/message-editable";
import MessageDisplay from "../DisplayMessage/message-display";
export default {
  props: ["index"],
  components: {
    "message-editable": MessageEditable,
    "message-display": MessageDisplay
  },
  computed: {
    messages: function() {
      return this.$store.state.messagingModule.conversation.messages;
    },
    isDraft: function() {
      if (this.messages[this.index].isDraft) return true;
      else return false;
    }
  }
};
</script>

<style>
.draftMessage {
  border: 2px double steelblue;
  border-radius: 5px;
}
</style>
