<template
  ><v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        large
        color="#1D3557"
        dark
        v-on:click="reloadConversations"
        v-bind="attrs"
        v-on="on"
      >
        <icon icon="sync-alt"></icon>
      </v-btn>
    </template>
    <span>Reload Conversations</span>
  </v-tooltip>
</template>
<script>
export default {
  methods: {
    reloadConversations: function() {
      this.$store.dispatch("messagingModule/loadConversationList");
    }
  }
};
</script>
