<template>
  <div>
    <actions-toolbar></actions-toolbar> <v-divider></v-divider>

    <conversation-list></conversation-list>
  </div>
</template>
<script>
import ConversationPreviewList from "./conversation-preview-list";
import ActionsToolbar from "../ActionToolbar/actions-toolbar";

export default {
  created: function() {
    this.$store.commit("messagingModule/setComponentAction", {
      value: "conversation-list"
    });
    //this.$store.commit("messagingModule/resetOffset");

    this.$store.commit("messagingModule/setSelectedConversationType", {
      value: this.$route.name
    });
  },
  mounted: async function() {
    console.log("In the mounted function in conversation list container");
    console.log(this.$store.state.securityModule.user);

    await this.$store.dispatch("messagingModule/getMessagingUser");
    await this.$store.dispatch("messagingModule/getUsers");
    await this.$store.dispatch("messagingModule/loadConversationList");
    await this.$store.dispatch("messagingModule/getMessagingGroups");
  },
  components: {
    "conversation-list": ConversationPreviewList,
    "actions-toolbar": ActionsToolbar
  },

  watch: {
    $route: function(to, from) {
      //this function only runs when routing from one conversation list type to another i.e. inbox to drafts/sent/search
      this.$store.commit("messagingModule/setSelectedConversationType", {
        value: this.$route.name
      });
      // this.$store.commit("messagingModule/resetOffset");
      if (from.name == "Search") {
        this.$store.commit("messagingModule/clearSearchPhrase");
        this.$store.commit("messagingModule/clearSearchIds");
      }
    }
  }
};
</script>
