<template>
  <div>
    <v-card width="1200" min-height="800">
      <v-data-table
        :loading="loading"
        loading-text="You have 0 groups"
        :headers="headers"
        :items="groups"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Messaging Groups</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <edit-group-modal :newGroup="true"></edit-group-modal>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-row>
            <v-col cols="auto">
              <edit-group-modal :group="item" class="mr-1"></edit-group-modal
            ></v-col>
            <v-col cols="auto"
              ><delete-group-modal :group="item"></delete-group-modal
            ></v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import deleteGroupModal from "./delete-group-modal";
import editGroupModal from "./edit-group-modal";
import { mapState } from "vuex";
export default {
  components: {
    "edit-group-modal": editGroupModal,
    "delete-group-modal": deleteGroupModal
  },

  computed: {
    ...mapState("messagingModule", ["groups"]),
    loading: function() {
      if (this.groups.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      return [
        {
          text: "Group Name",
          align: "start",
          value: "name"
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions"
        }
      ];
    }
  }
};
</script>
