<template>
  <div id="messageList">
    <v-expansion-panels v-model="selectedMessages" multiple>
      <v-expansion-panel
        v-for="(message, index) in messages"
        :key="index"
        :id="getElementId(index)"
        class="mb-4"
        >
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <message-header-panel
              :index="index"
              :action="getAction(index)"
            ></message-header-panel>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
          <message-content-panel :index="index"></message-content-panel>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import MessageHeaderPanel from "./message-header-panel";
import MessageContentPanel from "./message-content-panel";
import { mapMutations, mapState } from "vuex";
export default {
  created: function() {
    this.selectedMessages.push(this.lastMessageIndex);
  },
  components: {
    "message-header-panel": MessageHeaderPanel,
    "message-content-panel": MessageContentPanel
  },
  data() {
    return {
      selectedMessages: []
    };
  },
  computed: {
    scroll: function() {
      return this.$store.state.messagingModule.scrollToEndOfMessageList;
    },
    lastMessageIndex: function() {
      return this.messages.length - 1;
    },
    messages: function() {
      return this.$store.state.messagingModule.conversation.messages;
    },
    selected: function() {
      return this.$store.state.messagingModule.selected;
    }
  },
  methods: {
    getAction: function(index) {
      if (!this.selectedMessages.includes(index)) {
        return "collapsed";
      } else {
        return "expanded";
      }
    },
    getElementId: function(index) {
      return "message" + index;
    },
    scrollToNewMessage: function() {
      var elementID = "message" + this.lastMessageIndex;
      var element;
      setTimeout(function() {
        element = document.getElementById(elementID);
        element.scrollIntoView();
      }, 1000);
    }
  },
  watch: {
    selected: function() {
      this.selectedMessages = this.selected;
      if (!this.selectedMessages.includes(this.lastMessageIndex)) {
        this.selectedMessages.push(this.lastMessageIndex);
      }
    },

    lastMessageIndex: function() {
      if (!this.selectedMessages.includes(this.lastMessageIndex)) {
        this.selectedMessages.push(this.lastMessageIndex);
      }
    },
    scroll: function() {
      this.scrollToNewMessage();
      this.$store.commit("messagingModule/setScrollToEndOfMessageList", false);
    }
  }
};
</script>
