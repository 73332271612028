<template>
  <div v-if="isDraft">
    <message-preview-draft
      :index="index"
      :action="action"
    ></message-preview-draft>
  </div>
  <div v-else>
    <message-preview :index="index" :action="action"></message-preview>
  </div>
</template>
<script>
import MessagePreviewDraft from "../DisplayMessage/message-preview-draft";
import MessagePreview from "../DisplayMessage/message-preview";
export default {
  props: ["index", "action"],
  components: {
    "message-preview": MessagePreview,
    "message-preview-draft": MessagePreviewDraft
  },
  computed: {
    messages: function() {
      return this.$store.state.messagingModule.conversation.messages;
    },
    isDraft: function() {
      if (this.messages[this.index].isDraft) return true;
      else return false;
    }
  }
};
</script>
