<template>
  <v-autocomplete
    :items="options"
    item-text="filterField"
    color="blue-grey lighten-2"
    multiple
    dense
    chips
    deletable-chips
    return-object
    label="Recipients"
    hide-selected
    solo
    v-model="selectedRecipients"
    v-on:change="onChange"
    :search-input.sync="searchInput"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <span v-if="data.item.name">
          {{ data.item.name }}
        </span>
        <span v-else>
          {{ data.item.messagingAddress }}
        </span>
      </v-chip>
    </template>

    <template v-slot:item="data">
      <template v-if="data.item.name">
        <v-list-item-content
          ><v-list-item-title v-html="data.item.name"></v-list-item-title
        ></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content>
          <v-list-item-title
            v-html="data.item.messagingAddress"
          ></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["field", "value"],
  created() {
    this.users = this.userList;
    this.messagingGroups = this.groups;
    var i;
    for (i = 0; i < this.groups.length; i++) {
      this.messagingGroups[i].members = this.groups[i].members;
    }

    for (i = 0; i < this.messagingGroups.length; i++) {
      this.messagingGroups[i].filterField = this.messagingGroups[i].name;
      this.messagingGroups[i].objectType = "group";
    }
    for (i = 0; i < this.users.length; i++) {
      this.users[i].filterField = this.users[i].messagingAddress;
      this.users[i].objectType = "user";
    }
    for (i = 0; i < this.value.length; i++) {
      var recipient = this.value[i];
      recipient.filterField = recipient.messagingAddress;
      recipient.objectType = "user";
      this.selectedRecipients.push(recipient);
    }
  },
  data() {
    return {
      searchInput: "",
      selectedRecipients: [],
      users: [],
      messagingGroups: []
    };
  },
  computed: {
    ...mapState("messagingModule", ["userList", "groups"]),
    options: function() {
      var list = [];
      list.push({ header: "Groups" });
      list.push(...this.messagingGroups);
      list.push({ divider: true });
      list.push({ header: "Users" });
      list.push(...this.users);
      return list;
    }
  },
  methods: {
    remove(item) {
      console.log(
        "Item messaging address to remove is " + item.messagingAddress
      );
      var i;
      for (i = 0; i < this.selectedRecipients.length; i++) {
        if (this.selectedRecipients[i].filterField == item.filterField) {
          console.log("index is " + i);
          this.selectedRecipients.splice(i, 1);
        }
      }
      this.$emit("fieldChange", this.selectedRecipients);
    },
    onChange(event) {
      console.log("on change event recipients");
      this.searchInput = "";
      this.$emit("fieldChange", event);
    }
  }
};
</script>
