import User from "./User";
import Attachment from "./Attachment";

export default class Message {
  messageId?: number | null;
  sender?: User | null;
  sentAt?: Date | null;
  savedAt?: Date | null;
  recipients?: User[];
  content?: string | null;
  attachments?: Attachment[];
  isDraft?: boolean | null;
  public constructor(params: Message = {} as Message) {
    let {
      messageId = 0,
      sender = new User({ messagingUserId: 0, displayName: "chayala" }),
      sentAt = null,
      savedAt = null,
      recipients = new Array<User>(),
      content = "",
      attachments = new Array<Attachment>(),
      isDraft = true
    } = params;
    this.messageId = messageId;
    this.sender = sender;
    this.sentAt = sentAt;
    this.savedAt = savedAt;
    this.recipients = recipients;
    this.content = content;
    this.attachments = attachments;
    this.isDraft = isDraft;
  }
}
