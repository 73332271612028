import Message from "./Message";
import User from "./User";
import Attachment from "./Attachment";
export default class Conversation {
  conversationId?: number | null;
  subject?: string | null;
  messages?: Message[];
  isRead?: boolean | null;

  public constructor(params: Conversation = {} as Conversation) {
    let {
      conversationId = 0,
      subject = "",
      messages = new Array<Message>(
        new Message({
          messageId: 0,
          sender: new User({messagingUserId:0,displayName:"chayala"}),
          sentAt: null,
          recipients: new Array<User>(),
          content: "",
          attachments: new Array<Attachment>(),
          isDraft: true
        })
      ),
      isRead = false
    } = params;
    this.conversationId = conversationId;
    this.subject = subject;
    this.messages = messages;
    this.isRead = isRead;
  }
}
