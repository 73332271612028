<template>
  <v-chip
    class="n-v-chip-attachment"
    outlined
    color="blue-grey"
    dark
    label
    close
    @click:close="removeAttachment"
  >
    {{ name }} ( {{ readableSize }} )
  </v-chip>
</template>

<script>
export default {
  props: ["index", "subIndex"],
  methods: {
    async removeAttachment() {
      if (this.attachmentIsUploaded) {
        await this.removeAttachmentFromMessage();
      }
      this.$store.commit("messagingModule/removeAttachment", {
        index: this.index,
        subIndex: this.subIndex
      });
    },
    async removeAttachmentFromMessage() {
      return this.$store.dispatch(
        "messagingModule/removeAttachmentFromMessage",
        {
          messageIndex: this.index,
          attachmentIndex: this.subIndex
        }
      );
    }
  },
  computed: {
    attachmentIsUploaded: function() {
      return this.attachment.attachmentId != 0;
    },
    attachment: function() {
      return this.$store.state.messagingModule.conversation.messages[this.index]
        .attachments[this.subIndex];
    },
    name: function() {
      return this.attachment.fileName;
    },
    size: function() {
      return this.attachment.fileSizeBytes;
    },
    readableSize: function() {
      var calculatedSizeKiloBytes = Math.round(this.size / 1024);

      return (
        new Intl.NumberFormat("en-US").format(calculatedSizeKiloBytes) + "K"
      );
    }
  }
};
</script>
