<template>
  <v-card
    style="border:2px solid black"
    height="750"
    width="1100"
    v-if="loaded"
  >
    <div dense class="blue-grey white--text">
      <v-row dense align="center"
        ><v-col cols="auto"
          ><span class="ml-4 font-weight-regular">New Message</span></v-col
        >
        <v-col class="text-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark @click="saveAndClose" v-bind="attrs" v-on="on">
                <icon icon="times"></icon>
              </v-btn>
            </template>
            <span>Save and Close</span>
          </v-tooltip>
        </v-col></v-row
      >
    </div>

    <!-- <form-field
      class="re-mx-4 mt-2 compose-recipients"
      :field="recipients"
    ></form-field> -->
    <form-field
      class="re-mx-4 mt-2 compose-recipients"
      :field="recipients"
    ></form-field>

    <div class="re-mx-4">
      <form-field :field="subject"></form-field>
    </div>
    <div
      id="style-11"
      class="mt-4 re-mx-4"
      style="overflow-x: hidden; overflow-y: auto; height:45%;"
    >
      <form-field :field="content"></form-field>
    </div>
    <div
      id="style-11"
      class="mt-4 re-mx-4"
      style="overflow-x: hidden; overflow-y: auto; height:10%;"
    >
      <attachment-file-list :index="index"></attachment-file-list>
    </div>
    <message-actions class="ml-4" :index="index"></message-actions>
  </v-card>
</template>
<script>
import MessageActions from "../MessageActions/message-actions";
import AttachmentFileList from "../Attachments/AddAttachment/attachment-file-list";
import FormField from "../../../objs/Shared/FormField";
import { FieldTypes } from "../../../objs/Shared/FieldTypes";
import Recipients from "./recipients";
export default {
  props: ["index"],
  components: {
    "message-actions": MessageActions,
    "attachment-file-list": AttachmentFileList,
    recipients: Recipients
  },
  data() {
    Recipients;
    return {
      dialog: true
    };
  },
  created: function() {
    this.$store.commit("messagingModule/setComponentAction", {
      value: "compose"
    });
  },
  computed: {
    userList: function() {
      return this.$store.getters["messagingModule/getUserList"];
    },

    recipients: function() {
      return new FormField({
        tier: 4,
        moduleName: "messagingModule",
        objName: "conversation",
        propName: "messages",
        index: this.index,
        subPropName: "recipients",
        componentName: "recipients",
        label: "Recipients"
      });
    },
    subject: function() {
      return new FormField({
        tier: 1,
        moduleName: "messagingModule",
        objName: "conversation",
        propName: "subject",
        label: "Subject",
        componentName: FieldTypes.TEXT_FIELD
      });
    },
    content: function() {
      return new FormField({
        tier: 4,
        moduleName: "messagingModule",
        objName: "conversation",
        propName: "messages",
        index: this.index,
        subPropName: "content",
        componentName: FieldTypes.TEXT_EDITOR_FULL_EXTENSION_LIST
      });
    },
    messageContent: function() {
      return this.$store.state.messagingModule.conversation.messages[this.index]
        .content;
    },
    loaded: function() {
      return this.message != null;
    },
    message() {
      return this.$store.state.messagingModule.conversation.messages[
        this.index
      ];
    }
  },
  methods: {
    saveAndClose: function() {
      if (this.messageContent != "") {
        this.$store
          .dispatch("messagingModule/createNewMessage", {
            index: this.index
          })
          .then(response => {
            this.$store
              .dispatch("messagingModule/uploadAttachmentsForMessage", {
                index: this.index
              })
              .then(response => {
                this.$store.commit("uxModule/setShowSnackbar", false);
                this.$store.commit("uxModule/setSnackbarMsg", "Draft Saved");
                this.$store.commit("uxModule/setShowSnackbar", true);
                this.$router.push({ name: "Inbox" });
              });
          });
      } else {
        this.$router.push({ name: "Inbox" });
      }
    }
  }
};
</script>
<style></style>
