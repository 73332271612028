<template>
  <v-row>
    <attachment-thumbnail
      v-for="(attachment, index) in attachments"
      :key="attachment.attachmentId"
      :messageIndex="messageIndex"
      :attachmentIndex="index"
    ></attachment-thumbnail>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import AttachmentThumbnail from "./attachment-thumbnail";

export default {
  props: ["messageIndex"],
  components: {
    "attachment-thumbnail": AttachmentThumbnail
  },
  computed: {
    ...mapState("messagingModule", ["conversation"]),
    attachments: function() {
      return this.conversation.messages[this.messageIndex].attachments;
    }
  }
};
</script>

<style></style>
