import User from "./User";

export default class Group {
  groupId?: number;
  ownerId?: string;
  name?: string;
  members?: User[];
  public constructor(params: Group = {} as Group) {
    let {
      groupId = 0,
      ownerId = "",
      name = "",
      members = new Array<User>()
    } = params;
    this.groupId = groupId;
    this.ownerId = ownerId;
    this.name = name;
    this.members = members;
  }
}
