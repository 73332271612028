<template>
  <v-btn outlined color="#1D3557" dark v-on:click="reply">
    <icon icon="reply"></icon><span class="lowercase ml-1">Reply</span>
  </v-btn>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["index"],
  methods: {
    ...mapActions("messagingModule", ["replyToMessage"]),
    reply: function() {
      
      this.replyToMessage({
        messageIndex: this.index
      }); 
      this.$emit("respondActionClicked");
         
    }
  }
};
</script>
