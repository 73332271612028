<template>
  <v-btn dark class="lowercase" @click="downloadAttachment">
    <icon class="mr-2" icon="cloud-download-alt"></icon>Download
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
import { downloadFile } from "../../../../utils/download-utils";
export default {
  //props: ["url"],
  props: ["messageIndex", "attachmentIndex"],
  computed: {
    ...mapState("messagingModule", ["conversation"]),

    attachment: function() {
      return this.conversation.messages[this.messageIndex].attachments[
        this.attachmentIndex
      ];
    }
  },

  methods: {
    downloadAttachment: function () {
      this.$store
        .dispatch("messagingModule/getAttachment", {
          fileId: this.attachment.fileId,
        })
        .then((response) => {
          downloadFile(response.data, this.attachment.fileName);
        });
    },
  },
};
</script>

<style></style>
