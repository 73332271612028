<template>
  <v-list-item class="msg-conversation">
    <v-row>
            <v-col cols="12" md="6" class="d-flex">
                    <v-list-item-avatar color="#648c98">
                      <span class="white--text">
                        {{ avatarText }}
                      </span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="message.sender.displayName">
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-wrap" v-html="subtitleContent">
                      </v-list-item-subtitle>
                    </v-list-item-content>
            </v-col>
            <v-col cols="12" md="6" class="n-ptb-0">
                    <v-list-item-icon class="mt-md-6">
                      <icon v-if="hasAttachment" icon="paperclip" color="grey"></icon>
                    </v-list-item-icon>
                    <v-list-item-action class="justify-end">
                      <v-list-item-action-text v-text="dateAndDuration" class="subtitle-2">
                      </v-list-item-action-text>
                    </v-list-item-action>
            </v-col>
    </v-row>
  </v-list-item>
</template>
<script>
import * as moment from "moment";
import * as momentTimezone from "moment-timezone";
import { format } from "util";
export default {
  created() {
    setInterval(
      function() {
        this.updateCurrentTime();
      }.bind(this),
      60000
    );
  },

  props: ["index", "action"],
  data() {
    return {
      currentTime: new Date()
    };
  },
  computed: {
    messageSentAt: function() {
      return momentTimezone.utc(this.message.sentAt).tz("America/New_York");
    },

    selected: function() {
      return this.$store.state.messagingModule.selected;
    },
    isExpanded: function() {
      return this.action === "expanded";
    },
    message: function() {
      return this.$store.state.messagingModule.conversation.messages[
        this.index
      ];
    },
    hasAttachment: function() {
      return this.message.attachments.length > 0;
    },
    avatarText: function() {
      return this.message.sender.displayName.charAt(0);
    },
    recipients: function() {
      return this.message.recipients;
    },
    recipientsList: function() {
      var names = "";
      var i;
      for (i = 0; i < this.recipients.length; i++) {
        names += this.getReplacementName(this.recipients[i].displayName);

        if (i + 1 < this.recipients.length) {
          names += ", ";
        }
      }
      return names;
    },

    subtitleContent: function() {
      if (this.action == "collapsed") {
        return this.messagePreview;
      } else {
        return "to " + this.recipientsList;
      }
    },
    messagePreview: function() {
      var reg = new RegExp("\\<.*?\\>", "g");
      return this.message.content.replace(reg, "").substr(0, 200);
    },
    dateFormatString: function() {
      switch (this.durationUnit) {
        case "hours":
        case "minutes":
          return "h:mm A";
          break;
        case "months":
        case "days":
          return "ddd, MMMM D, h:mm A";
          break;
        case "years":
          return "MM/DD/YY";
          break;
      }
    },

    dateAndDuration: function() {
      var dateAndDuration =
        this.formattedDateSent +
        "  (" +
        this.duration +
        " " +
        this.durationUnit;
      if (this.duration == 1) {
        dateAndDuration = dateAndDuration.slice(0, dateAndDuration.length - 1);
      }
      dateAndDuration += " ago) ";
      return dateAndDuration;
    },
    formattedDateSent: function() {
      return this.messageSentAt.format(this.dateFormatString);
    },
    duration: function() {
      var today = moment(this.currentTime);
      var messageDate = moment(this.messageSentAt);
      return today.diff(messageDate, this.durationUnit.toString());
    },
    durationUnit: function() {
      if (moment(this.currentTime).diff(this.messageSentAt, "day") == 0) {
        if (moment(this.currentTime).diff(this.messageSentAt, "hour") == 0) {
          //sameHour
          return "minutes";
        } else {
          //sameDay
          return "hours";
        }
      } else {
        if (moment(this.currentTime).diff(this.messageSentAt, "year") == 0) {
          if (moment(this.currentTime).diff(this.messageSentAt, "month") == 0) {
            //sameMonth
            return "days";
          } else {
            //same year diff month
            return "months";
          }
        } else {
          //diff year
          return "years";
        }
      }
    }
  },
  methods: {
    updateCurrentTime: function() {
      this.currentTime = new Date();
    },
    getReplacementName: function(name) {
      if (name == this.$store.state.messagingModule.user.displayName)
        return "me";
      else return name;
    }
  }
};
</script>
