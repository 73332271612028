<template>
  <v-list-item>
    <v-list-item-icon>
      <icon icon="edit"></icon>
    </v-list-item-icon>
    <v-list-item-content>
      <span class=".text-heading-5 mt-1 grey--text">DRAFT</span>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {};
</script>
