<template>
 <div class="message"> <message-preview :index="0" action="expanded"></message-preview>
  <message-display index="0"></message-display></div>
</template>

<script>
  import MessagePreview from "../DisplayMessage/message-preview";
  import MessageDisplay from "../DisplayMessage/message-display";

  export default {
    components: {
      "message-preview": MessagePreview,
      "message-display": MessageDisplay
    }
  }
</script>
<style>  .message {
    background-color: white;
  }
</style>
