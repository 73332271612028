<template>
  <!-- <v-text-field outlined> -->
  <div>
    <attachment-file-item
      v-for="(file, i) in attachments"
      :key="i"
      :index="index"
      :subIndex="i"
    ></attachment-file-item>
  </div>
  <!-- </v-text-field> -->
</template>
<script>
import AttachmentFileItem from "./attachment-file-item";
export default {
  props: ["index"],
  components: {
    "attachment-file-item": AttachmentFileItem
  },
  computed: {
    attachments: function() {
      return this.$store.state.messagingModule.conversation.messages[this.index]
        .attachments;
    }
  }
};
</script>
