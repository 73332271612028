<template>
  <v-btn outlined color="#1D3557" dark v-on:click="replyAll">
    <icon icon="reply-all"></icon
    ><span class="lowercase ml-1">Reply All</span>
  </v-btn>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["index"],
  methods: {
    ...mapActions("messagingModule", ["replyToAll"]),
    replyAll: function() {
      
      this.replyToAll({ messageIndex: this.index });  this.$emit("respondActionClicked");
    
    }
  }
};
</script>
