import Callback from "../components/Security/callback";
import ConversationListContainer from "../components/ConversationPreviewList/conversation-list-container";
import Conversation from "../components/ConversationMessage/ConversationMessageList/conversation-container";
import ComposeMessage from "../components/ConversationMessage/ComposeMessage/compose-message";
import SingleDraftContainer from "../components/ConversationMessage/ConversationMessageList/single-draft-container";
import GroupList from "../components/Groups/groups-table";
export const routes = [
  {
    name: "Inbox",
    path: "/inbox",
    icon: "inbox",
    title: "Inbox",
    component: ConversationListContainer,
    onNavBar: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Inbox",
    path: "/",
    icon: "inbox",
    title: "Inbox",
    component: ConversationListContainer,
    onNavBar: false,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Conversation",
    path: "/conversation",
    icon: "pen",
    title: "Conversation",
    component: Conversation,
    onNavBar: false,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Compose",
    path: "/compose",
    icon: "pen",
    title: "Compose",
    component: ComposeMessage,
    props: true,
    onNavBar: false,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Drafts",
    path: "/drafts",
    icon: "edit",
    title: "Draft",
    component: ConversationListContainer,
    onNavBar: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Sent",
    path: "/sent",
    icon: "paper-plane",
    title: "Sent",
    component: ConversationListContainer,
    onNavBar: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Search",
    path: "/search",
    icon: "pen",
    title: "Search",
    component: ConversationListContainer,
    onNavBar: false,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "DraftContainer",
    path: "/draft",
    icon: "pen",
    title: "DraftContainer",
    component: SingleDraftContainer,
    onNavBar: false,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Groups",
    path: "/groups",
    icon: "users",
    title: "Manage Groups",
    component: GroupList,
    onNavBar: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false
  }
];
