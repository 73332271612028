<template>
  <div>
    <v-list two-line>
      <v-list-item-group multiple>
        <template v-for="(item, index) in messages">
          <conversation-item
            :conversationItem="item"
            :key="index"
            :index="index"
          ></conversation-item>

          <v-divider v-if="index + 1 < messages.length"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
import ConversationItem from "./conversation-preview-item";
export default {
  components: {
    "conversation-item": ConversationItem
  },
  computed: {
    messages: function() {
      return this.$store.getters[
        "messagingModule/getMessagesForSelectedConversation"
      ];
    }
  }
};
</script>
