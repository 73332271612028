<template>
  <div>
    <v-row dense align="center"
      ><v-btn
          color="#1D3557"
          :dark="notEmpty"
          @click="sendMessage"
          :disabled="!notEmpty"
          >Send</v-btn
        >
        <form-field
          class="mb-4 ml-4"
          :field="fileInputField"
          v-on:fieldChange="addAttachments"
        ></form-field>
        <v-spacer></v-spacer>
        <delete-message-model
          class="mr-4"
          type="Message"
          :index="index"
        ></delete-message-model>
    </v-row>
  </div>
</template>
<script>
import DeleteConversationMessageModel from "../../delete-conversation-message-model";
import FormField from "../../../objs/Shared/FormField";
import { FieldTypes } from "../../../objs/Shared/FieldTypes";
export default {
  props: ["index"],
  components: {
    "delete-message-model": DeleteConversationMessageModel
  },
  computed: {
    fileInputField: function() {
      return new FormField({
        tier: -1,
        value: "",
        componentName: FieldTypes.FILE_INPUT
      });
    },
    action: function() {
      return this.$store.state.messagingModule.action;
    },
    subject: function() {
      return this.$store.state.messagingModule.conversation.subject;
    },
    content: function() {
      var reg = new RegExp("\\<.*?\\>", "g");
      return this.$store.state.messagingModule.conversation.messages[
        this.index
      ].content.replace(reg, "");
    },
    recipientsNotEmpty: function() {
      return (
        this.$store.state.messagingModule.conversation.messages[this.index]
          .recipients.length > 0
      );
    },
    newMessage: function() {
      return (
        this.$store.state.messagingModule.conversation.messages[this.index]
          .messageId == 0
      );
    },
    attachments: function() {
      return this.$store.state.messagingModule.conversation.messages[this.index]
        .attachments.length;
    },
    notEmpty: function() {
      console.log("content length" + this.content.length);
      if (
        this.recipientsNotEmpty &&
        (this.subject.length > 0 ||
          this.content.length > 0 ||
          this.attachments > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    async createMessage() {
      return this.$store.dispatch("messagingModule/createNewMessage", {
        index: this.index
      });
    },
    async sendMessage() {
      if (this.newMessage) {
        await this.createMessage();
      }
      this.$store
        .dispatch("messagingModule/uploadAttachmentsForMessage", {
          index: this.index
        })
        .then(response => {
          this.$store
            .dispatch("messagingModule/sendMessage", {
              index: this.index
            })
            .then(response => {
              if (
                this.action == "compose" ||
                this.action == "singleDraftConversation"
              ) {
                this.$router.push({ name: "Inbox" });
              }
            });
        });
    },

    addAttachments: function(files) {
      var i;
      for (i = 0; i < files.length; i++) {
        this.$store.commit("messagingModule/addAttachment", {
          messageIndex: this.index,
          file: files[i]
        });
      }
    }
  }
};
</script>
