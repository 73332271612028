<template>
  <div>
    <actions-toolbar></actions-toolbar> <v-divider></v-divider>

    <v-row>
      <v-col cols="12" sm="6">
        <form-field :field="subject"></form-field>
      </v-col>
    </v-row>
    <v-card elevation="3">
      <div class="mar-6">
        <message-editable index="0"></message-editable>
      </div>
    </v-card>
  </div>
</template>
<script>
import MessageEditable from "../DisplayMessage/message-editable";
import ActionsToolbar from "../../ActionToolbar/actions-toolbar";

import FormField from "../../../objs/Shared/FormField";
import { FieldTypes } from "../../../objs/Shared/FieldTypes";
export default {
  created: function() {
    this.$store.commit("messagingModule/setComponentAction", {
      value: "singleDraftConversation"
    });
    this.$store.commit("messagingModule/setDeleteDraftClicked", false);
  },
  components: {
    "message-editable": MessageEditable,
    "actions-toolbar": ActionsToolbar
  },
  computed: {
    subject: function() {
      return new FormField({
        tier: 1,
        moduleName: "messagingModule",
        objName: "conversation",
        propName: "subject",
        label: "Subject",
        componentName: FieldTypes.TEXT_FIELD
      });
    }
  }
};
</script>
